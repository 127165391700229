import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home', //常見問題
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/Question',
    name: 'commonQuestion', //问题列表
    component: () => import('../views/CommonQuestion.vue'),
  },
  {
    path: '/Complaint',
    name: 'complaint', //投訴建議
    component: () => import('../views/ComplaintAdvice.vue'),
  },
  {
    path: '/advises/create',
    name: 'AdviseCreate', //我要回饋/客服問題
    component: () => import('../views/AdviseCreate.vue'),
  },
  {
    path: '/advises',
    name: 'AdviseList', //回饋記錄
    component: () => import('../views/AdviseList.vue'),
  },
  {
    path: '/detail',
    name: 'RecordDetail', //問題詳情
    component: () => import('../views/RecordDetail.vue'),
  },
  {
    path: '/aboutUs',
    name: 'aboutUs', //關於我們
    component: () => import('../views/aboutUs.vue'),
  },
  {
    path: '/userAgreement',
    name: 'userAgreement', //用戶協議
    component: () => import('../views/UserAgreement.vue'),
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy', //隱私政策
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/buyerKnows',
    name: 'buyerKnows', //買家需知
    component: () => import('../views/BuyersKnows.vue'),
  },
  {
    path: '/payResult',
    name: 'payResult',
    component: () => import('../views/payResult.vue'),
  },
  {
    path: '/login',
    name: 'login', //元氣邀請碼
    component: () => import('../views/logIn.vue'),
  },
  {
    path: '/express',
    name: 'express',
    component: () => import('../views/expressDetail.vue'),
  },
  {
    path: '/couponGroup',
    name: 'couponGroup', //領券中心
    component: () => import('../views/couponGroup.vue'),
  },
  {
    path: '/comment',
    name: 'comment', //我要評論
    component: () => import('../views/CommentCreate.vue'),
  },
  {
    path: '/authoRization',
    name: 'authoRization', //假一賠十
    component: () => import('../views/authoRization.vue'),
  },
  {
    path: '/authoRizationDetails',
    name: 'authoRizationDetails', //假一賠十保障
    component: () => import('../views/authoRizationDetails.vue'),
  },
  {
    path: '/limitActivity',
    name: 'limitActivity', //限時特惠
    component: () => import('../views/limitActivity.vue'),
  },
  {
    path: '/questionnaireSurvey',
    name: 'questionnaireSurvey', //有獎問卷
    component: () => import('../views/questionnaireSurvey.vue'),
  },
  {
    path: '/questionnaireRules',
    name: 'questionnaireRules', //有獎問卷活動規則
    component: () => import('../views/questionnaireRules.vue'),
  },
  {
    path: '/SignIn',
    name: 'SignIn', //簽到頁
    component: () => import('../views/SignIn.vue'),
  },
  {
    path: '/SignInRules',
    name: 'SignInRules', //簽到頁規則
    component: () => import('../views/SignInRules.vue'),
  },
  {
    path: '/SendCoin',
    name: 'SendCoin', //開盒送幣
    component: () => import('../views/SendCoin.vue'),
  },
  {
    path: '/SendCoinRules',
    name: 'SendCoinRules', //開盒送幣規則頁
    component: () => import('../views/SendCoinRules.vue'),
  },
  {
    path: '/SendCoinReward',
    name: 'SendCoinReward', //我的獎勵
    component: () => import('../views/SendCoinReward.vue'),
  },
  {
    path: '/FunctionCard',
    name: 'FunctionCard', //功能卡
    component: () => import('../views/FunctionCard.vue'),
  },
  {
    path: '/CouponCard',
    name: 'CouponCard', //我的优惠券
    component: () => import('../views/CouponCard.vue'),
  },
  {
    path: '/InviteRewards',
    name: 'InviteRewards', //邀請有獎
    component: () => import('../views/InviteRewards.vue'),
  },
  {
    path: '/inviteDetail',
    name: 'inviteDetail', //邀請有禮
    component: () => import('../views/inviteDetail.vue'),
  },
  {
    path: '/christmasCarnival',
    name: 'christmasCarnival',  //圣诞狂欢
    component: () => import('../views/christmasCarnival.vue')
  },
  {
    path: '/inviteFriends',
    name: 'inviteFriends', //邀好友-拿现金
    component: () => import('../views/inviteFriends.vue'),
  },
  {
    path: '/starGatheringPlan',
    name: 'starGatheringPlan', //聚星计划
    component: () => import('../views/starGatheringPlan.vue'),
  },
  {
    path: '/activity/luckyWheel',
    name: 'luckyWheel', //大轮盘抽奖
    component: () => import('../views/activity/luckyWheel.vue'),
  },
  {
    path: '/festivaIActivity',
    name: 'festivaIActivity', //月末限定優惠
    component: () => import('../views/festivaIActivity.vue'),
  },
  {
    path: '/midAutumnFestival',
    name: 'midAutumnFestival', //FUN價慶中秋
    component: () => import('../views/midAutumnFestival.vue'),
  },
  {
    path: '/upGradeRules',
    name: 'upGradeRules', //獎品升級规则
    component: () => import('../views/upGradeRules.vue'),
  },
  {
    path: '/bargainActivity',
    name: 'bargainActivity', //助力殺價
    component: () => import('../views/bargainActivity.vue'),
  },
  {
    path: '/bargainRules',
    name: 'bargainRules', //助力殺價规则
    component: () => import('../views/bargainRules.vue'),
  },
  {
    path: '/bargainShare',
    name: 'bargainShare', //助力殺價分享
    component: () => import('../views/bargainShare.vue'),
  },
  {
    path: '/bargainAssist',
    name: 'bargainAssist', //助力好友殺價
    component: () => import('../views/bargainAssist.vue'),
  },
  {
    path: '/bargainRecords',
    name: 'bargainRecords',
    component: () => import('../views/bargainRecords.vue'),
  },
  {
    path: '/doubleEleven',
    name: 'doubleEleven', //双十一
    component: () => import('../views/doubleEleven.vue'),
  },
  {
    path: '/doubleElevenRules',
    name: 'doubleElevenRules', //双十一规则
    component: () => import('../views/doubleElevenRules.vue'),
  },
  {
    path: '/luckyDraw',
    name: 'luckyDraw', //转盘抢券 双十二
    component: () => import('../views/luckyDraw.vue'),
  },
  {
    path: '/luckyDrawRules',
    name: 'luckyDrawRules', //转盘抢券 双十二 规则
    component: () => import('../views/luckyDrawRules.vue'),
  },
  {
    path: '/worldCup',
    name: 'worldCup', //世界杯
    component: () => import('../views/worldCup.vue'),
  },
  {
    path: '/worldCupRules',
    name: 'worldCupRules', //世界杯规则
    component: () => import('../views/worldCupRules.vue'),
  },
  {
    path: '/doubleEgg',
    name: 'doubleEgg', //双蛋--碎片
    component: () => import('../views/doubleEgg.vue'),
  },
  {
    path: '/doubleEggRules',
    name: 'doubleEggRules', //双蛋规则
    component: () => import('../views/doubleEggRules.vue'),
  },
  {
    path: '/SpringFestival',
    name: 'SpringFestival', //金彩539
    component: () => import('../views/SpringFestival.vue'),
  },
  {
    path: '/SpringFestivalRules',
    name: 'SpringFestivalRules', //金彩539规则
    component: () => import('../views/SpringFestivalRules.vue'),
  },
  {
    path: '/betTicket',
    name: 'betTicket', //我的奖券
    component: () => import('../views/betTicket.vue'),
  },
  {
    path: '/LanternFestival',
    name: 'LanternFestival', //元宵活动
    component: () => import('../views/LanternFestival.vue'),
  },
  {
    path: '/LanternFestivalRules',
    name: 'LanternFestivalRules', //元宵活动规则
    component: () => import('../views/LanternFestivalRules.vue'),
  },
  {
    path: '/qqWj',
    name: 'qqWj',
    component: () => import('../views/QQWJ.vue'),
  },
  {
    path: '/RepostPoster',
    name: 'RepostPoster', //新人首日特權
    component: () => import('../views/RepostPoster.vue'),
  },
  {
    path: '/limitGiftPack',
    name: 'limitGiftPack', //省錢包
    component: () => import('../views/limitGiftPack.vue'),
  },
  {
    path: '/limitGiftPackRules',
    name: 'limitGiftPackRules', //省錢包规则
    component: () => import('../views/limitGiftPackRules.vue'),
  },
  {
    path: '/hotGoods',
    name: 'hotGoods', //爆款商品
    component: () => import('../views/hotGoods.vue'),
  },
  {
    path: '/grabTickets',
    name: 'grabTickets', //月中狂歡盛典 抢券活动
    component: () => import('../views/grabTickets.vue'),
  },
  {
    path: '/prizeDraw',
    name: 'prizeDraw', //九宫格轮盘抽奖活动
    component: () => import('../views/prizeDraw.vue'),
  },
  {
    path: '/prizeDrawRules',
    name: 'prizeDrawRules',
    component: () => import('../views/prizeDrawRules.vue'),
  },
  {
    path: '/activity/lottery',
    name: 'lotteryDraw', //夏日冰爽抽不停 九宫格轮盘抽奖
    component: () => import('../views/activity/lottery.vue'),
  },
  {
    path: '/activity/lotteryRules',
    name: 'lotteryRules',
    component: () => import('../views/activity/lotteryRules.vue'),
  },
  {
    path: '/advertise',
    name: 'advertise', //超級免單福利！
    component: () => import('../views/advertise.vue'),
  },
  {
    path: '/gameAdvertise',
    name: 'gameAdvertise', //游戏预约页面
    component: () => import('../views/gameAdvertise.vue'),
  },
  {
    path: '/storedValue',
    name: 'storedValue', //储值有礼页面
    component: () => import('../views/storedValue.vue'),
  },
  {
    path: '/dailyTasks',
    name: 'dailyTasks', //每日任务
    component: () => import('../views/dailyTasks.vue'),
  },
  {
    path: '/weeklyTask',
    name: 'weeklyTask', //每周任务
    component: () => import('../views/weeklyTask.vue'),
  },
  {
    path: '/fourPrivilege',
    name: 'fourPrivilege', //4大特权活动落地页
    component: () => import('../views/fourPrivilege.vue'),
  },
  {
    path: '/couponAward',
    name: 'couponAward', //618年中狂歡
    component: () => import('../views/couponAward.vue'),
  },
  {
    path: '/couponAwardRules',
    name: 'couponAwardRules', //618年中狂歡规则页
    component: () => import('../views/couponAwardRules.vue'),
  },
  {
    path: '/activity/gamePong',
    name: 'gamePong', // 棒球高手游戏页面
    component: () => import('../views/activity/gamePong.vue'),
  },
  {
    path: '/activity/gameShare',
    name: 'gameShare', // 棒球高手游戏分享页面
    component: () => import('../views/activity/gameShare.vue'),
  },
  {
    path: '/accountDeletion',
    name: 'accountDeletion', // 账号管理-消除账号
    component: () => import('@/views/accountDeletion.vue'),
  },
  {
    path: '/activity/buyLimit',
    name: 'buyLimit', // 九月限时购
    component: () => import('../views/activity/buyLimit.vue'),
  },
  {
    path: '/activity/freeActivity',
    name: 'freeActivity', // 免单盲盒活动
    component: () => import('../views/activity/freeActivity.vue'),
  },
  {
    path: '/activity/activityPage',
    name: 'activityPage', // iPhone15輕鬆拿活动
    component: () => import('../views/activity/activityPage.vue'),
  },
  {
    path: '/activity/midFestivalActivity',
    name: 'midFestivalActivity', // 中秋活动
    component: () => import('../views/activity/midFestivalActivity.vue'),
  },
  {
    path: '/activity/chungYeungFestival',
    name: 'chungYeungFestival', // 重阳节活动
    component: () => import('../views/activity/chungYeungFestival.vue'),
  },
  {
    path: '/activity/nationalDay',
    name: 'nationalDay', // 国庆活动
    component: () => import('../views/activity/nationalDay.vue'),
  },
  {
    path: '/activity/dailyActivity',
    name: 'dailyActivity',//每日活动
    component: () => import('../views/activity/dailyActivity.vue'),
  },
  {
    path: '/activity/elevenStorageValue',
    name: 'elevenStorageValue', //双十一早鸟储值
    component: () => import('../views/activity/elevenStorageValue.vue'),
  },
  {
    path: '/activity/shoppingFestival',
    name: 'shoppingFestival', //双十一购物节
    component: () => import('../views/activity/shoppingFestival.vue'),
  },
  {
    path: '/activity/storedCode',
    name: 'storedCode', //储值加码
    component: () => import('../views/activity/storedCode.vue'),
  },
  {
    path: '/activity/doubleLuckyDraw',
    name: 'doubleLuckyDraw', //2023-双十一抽奖/九宫格
    component: () => import('../views/activity/doubleLuckyDraw.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.VUE_APP_BASE_API,
  routes,
});

export default router;
