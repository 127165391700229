import request from '@/utils/request'

// 获取用户邀请码 获取客户端用户详细信息
export function getUserInfo(params = {}) {
  return request({
    method: 'get',
    url: '/api/user/detail/v1.0.0',
    params,
  })
}


// 获取用户邀请分页
export function invitedpage(params = {}) {
  return request({
    method: 'get',
    url: '/api/coin/inout/invited/page/v1.0.0',
    params,
  })
}

// 抢先申请内测
export function buttonClick(deviceId) {
  return request({
    method: 'get',
    url: `/api/log/buttonClick/v1.0.0?deviceId=` + deviceId,
  })
}

// 进入页面
export function pageIn(deviceId) {
  return request({
    method: 'get',
    url: `/api/log/pageIn/v1.0.0?deviceId=` + deviceId,
  })
}
