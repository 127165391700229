import Vue from 'vue';
import Vuex from 'vuex';
import { getToken, getUserDetail, getNodeId, getSessionId } from '@/utils/localStorage'
import { Toast } from 'vant';
Vue.use(Toast)
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    historyDetail: {},
    token: getToken() || '',
    userDetail: getUserDetail() || {},
    nodeId: getNodeId() || '',
    sessionId: getSessionId() || '',
  },

  mutations: {
    setHistoryDetail(state, value) {
      state.historyDetail = value;
    },

    setUserToken(state,value) {
      state.token = value;
    },

    setUserDetail(state,value) {
      state.userDetail = value;
    },

    setNodeId(state,value) {
      state.nodeId = value;
    },

    setSessionId(state,value) {
      state.sessionId = value;
    },
  },

  actions: {
  },

  modules: {
  },
});
