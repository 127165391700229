import request from '@/utils/request'

// 新增意见与反馈
export function create(data) {
  return request({
    method: 'POST',
    url: '/api/userAdvise/saveAdvise/v1.0.0',
    data,
  })
}
