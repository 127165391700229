import axios from 'axios'
import store from '../store/index'
import router from '../router';
import Vue from 'vue';
import { Toast } from 'vant';
Vue.use(Toast);
import { removeToken, removeUserDetail, removeNodeId, removeSessionId } from '@/utils/localStorage'
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000,
})

request.interceptors.request.use(
  async (config) => {
    let token
    if (store.state.token) {
      token = store.state.token
    } else if (store.state.tokenKey) {
      token = store.state.tokenKey
    }
    // else {
    //   token = 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl9leHBpcmVfdGltZSI6MTY4MTExMTEwOTU5MCwibG9naW5fdXNlcl9rZXkiOiJhcHA6MjA5In0.l_8JXL1-TOEPipgwutEd1BVQ11Eu9WhcYBN9-F-wiHsRDAkUG5gdskciL9LTe_eHGbWUAM18aSGyxQUKCYVsvw'
    // }
    let systemName = "IOS";
    let u = navigator.userAgent;
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
    if (!isIOS) {
      systemName = "Android";
    }
    config.headers['System-Name'] = systemName;
    config.headers.Authorization = `Bearer ${token}`;
    return config
  },
  (error) => Promise.reject(error),
)

request.interceptors.response.use(
  response => {
    // 状态码
    const code = response.data.code;
    // 获取错误信息
    const msg = response.data.msg;
    if (code === 401) {
      removeToken();
      removeUserDetail();
      removeNodeId();
      removeSessionId();
      store.state.token = '';
      store.state.nodeId = '';
      store.state.sessionId = '';
      Toast.fail('未登錄/登錄失效,請登錄后再進行操作。');
      return Promise.reject('無效的會話，或者會話已過期，請重新登錄。')
    } else {
      return response.data
    }
  },
  // (response) => {
  //   return response.data
  // },
  error => Promise.reject(error),
)

export default request
