import request from '@/utils/request'

// 用户操作记录列表
export function addlog(cid = null, data) {
  return request({
    method: 'post',
    headers: { "Device-Id": cid },
    url: '/api/oper/log/add',
    data,
  })
}

// 手机验证码登录
export function phoneCodeLogin(data) {
  return request({
    method: 'POST',
    url: '/api/loginByPhoneCode/v1.0.0',
    data,
  })
}

// 获取手机验证码
export function getPhoneCode(data) {
  return request({
    method: 'POST',
    url: '/api/getPhoneCode/v1.0.0',
    data,
  })
}

// 手機號或者郵箱賬號密碼登錄
export function smsOrEmailLogin(cid = 'abcde', data) {
  return request({
    method: 'POST',
    headers: { "device-Id": cid },
    url: '/api/smsOrEmailLogin/v1.0.0',
    data,
  })
}

// 纯盲盒分页2.0
export function blindBoxPage(params) {
  return request({
    method: 'get',
    url: '/api/blindBox/page/v2.0.0',
    params,
  })
}
