import Vue from 'vue';
// import Vconsole from 'vconsole'
// new Vconsole()
import {
  Button,
  Cell,
  CellGroup,
  Collapse,
  CollapseItem,
  Field,
  Icon,
  NavBar,
  Toast,
  Popup,
  Dialog,
} from 'vant';
import App from './App.vue';
import router from './router';
import store from './store';
import api from '@/api'
import VueClipboard from 'vue-clipboard2'
import VueLuckyCanvas from '@lucky-canvas/vue'
import '@/assets/css/font.css'
import '@/assets/css/publicCss.css'
import VueBridgeWebview from './utils/bridge.js'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import '@/mixins';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d);
import VueAwesomeSwiper from 'vue-awesome-swiper'
import "swiper/css/swiper.css"
Vue.use(VueAwesomeSwiper)

Vue.use(VueBridgeWebview)
let WVJBIframe = document.createElement('iframe')
WVJBIframe.style.display = 'none'
WVJBIframe.src = 'https://__bridge_loaded__'
document.documentElement.appendChild(WVJBIframe)

Vue.use(VueLuckyCanvas)
Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Field);
Vue.use(Icon);
Vue.use(NavBar);
Vue.use(VueClipboard);
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Dialog);

Vue.prototype.$api = api
Vue.config.productionTip = false;
Vue.prototype.$Toast = Toast
Vue.prototype.$Dialog = Dialog
Vue.prototype.FingerprintJS = FingerprintJS
Vue.prototype.$AdyenCheckout = AdyenCheckout;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
router.beforeEach((to, from, next)=>{
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  window.pageYOffset = 0;
  next()
})
