import store from '../store/index'

// Cookie工具类，用于存储和获取cookie
const TOKEN_KEY = 'user_token'
const USET_KEY = 'user_details'
const NODE_KEY = 'node_Id'
const SESSION_KEY = 'session_id'
// 保存token
export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token)
}
// 获取token
export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY)
}
// 删除token
export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY)
  store.commit("setUserToken", "");
}
// 保存用户信息
export const setUserDetail = (detail) => {
  localStorage.setItem(USET_KEY, JSON.stringify(detail))
}
// 获取用户信息
export const getUserDetail = () => {
  return JSON.parse(localStorage.getItem(USET_KEY))
}
// 删除用户信息
export const removeUserDetail = () => {
  localStorage.removeItem(USET_KEY)
  store.commit("setUserDetail", "");
}
// 保存nodeId
export const setNodeId = (value) => {
  localStorage.setItem(NODE_KEY, value)
}
// 获取nodeId信息
export const getNodeId = () => {
  return localStorage.getItem(NODE_KEY)
}
// 删除nodeId信息
export const removeNodeId = () => {
  localStorage.removeItem(NODE_KEY)
  store.commit("setNodeId", "");
}
// 保存sessionId
export const setSessionId = (value) => {
  localStorage.setItem(SESSION_KEY, value)
}
// 获取sessionId信息
export const getSessionId = () => {
  return localStorage.getItem(SESSION_KEY)
}
// 删除sessionId信息
export const removeSessionId = () => {
  localStorage.removeItem(SESSION_KEY)
  store.commit("setSessionId", "");
}

